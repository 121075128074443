@font-face {
  font-family: Lato;
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url("/static/fonts/Lato/Lato-Black.woff2") format('woff2');
}
@font-face {
  font-family: Lato;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("/static/fonts/Lato/Lato-Regular.woff2") format('woff2');
}
